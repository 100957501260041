import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Company = {
  key: string;
  value: string;
};

const initialState = {
  activeCompany: {},
};

const companyUiSlice = createSlice({
  name: "companyUi",
  initialState,
  reducers: {
    setActiveCompany: (state, action: PayloadAction<Company>) => {
      state.activeCompany = action.payload;
    },
  },
});

export const { setActiveCompany } = companyUiSlice.actions;
export default companyUiSlice.reducer;
