import { Provider } from "react-redux";
import { Routes } from "@/Routes";
import { SentryWrapper } from "@/hooks/sentry";
import { store } from "@/store";
import { Toaster } from "react-hot-toast";

export default function App() {
  return (
    <SentryWrapper>
      <Provider store={store}>
        <Routes />
      </Provider>
      <Toaster />
    </SentryWrapper>
  );
}
