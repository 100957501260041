import {
  Sidebar,
  SidebarContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { useAppSelector } from "@/hooks/redux";
import { useNavigate } from "react-router-dom";

export const SettingsSidebar = ({ isMobile }: { isMobile: boolean }) => {
  const { setOpenMobile } = useSidebar();
  const navigate = useNavigate();

  const teamUi = useAppSelector((state) => state.teamUi);
  const isOwnerOfTeam: any = teamUi.isOwner;

  const handleClick = (href: string) => {
    navigate(href);
    setOpenMobile(false); // Close the sidebar for mobile
  };

  const isCurrentPath = (path: string) => window.location.pathname === path;

  return (
    <Sidebar
      variant="sidebar"
      collapsible={isMobile ? "offcanvas" : "none"}
      className="relative w-64 border-none"
    >
      <SidebarContent className="py-6">
        <SidebarMenu className="flex flex-col gap-2">
          {[
            { title: "Contact Info", href: "/settings/contact-info" },
            { title: "Profile", href: "/settings/profile" },
            { title: "Workspace", href: "/settings/workspace" },
            ...(isOwnerOfTeam
              ? [{ title: "Members", href: "/settings/members" }]
              : []),
          ].map((item) => (
            <SidebarMenuItem key={item.href}>
              <SidebarMenuButton
                onClick={() => handleClick(item.href)}
                isActive={isCurrentPath(item.href)}
                className={`text-sm font-medium px-4 py-2 ${
                  isCurrentPath(item.href)
                    ? "bg-zinc-100 text-zinc-950"
                    : "text-zinc-950 hover:bg-zinc-100"
                }`}
              >
                {item.title}
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarContent>
    </Sidebar>
  );
};
