import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const stepsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "stepsApi",
    baseUrl: "/api/steps",
  }),
  reducerPath: "stepsApi",
  endpoints: (builder) => ({
    createStepEvent: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/events`,
        method: "POST",
        body: data,
      }),
    }),
    createStepDocument: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/documents${
          data.documentTemplateId
            ? `?documentTemplateId=${data.documentTemplateId}`
            : ""
        }`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useCreateStepEventMutation, useCreateStepDocumentMutation } =
  stepsApi;
