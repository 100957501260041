import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const onboardingApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "onboardingApi",
    baseUrl: "/api/onboarding",
  }),
  reducerPath: "onboardingApi",
  endpoints: (builder) => ({
    getonboarding: builder.query<any, {}>({
      query: () => {
        return ``;
      },
    }),
  }),
});

export const { useGetonboardingQuery } = onboardingApi;
