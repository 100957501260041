import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type EventTemplatesWizardState = {
  description: string;
  order: number;
  eventId: string;
};

const initialState: EventTemplatesWizardState = {
  description: "",
  order: 0,
  eventId: "",
};

const eventTemplatesWizardSlice = createSlice({
  name: "eventTemplatesWizard",
  initialState,
  reducers: {
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setOrder: (state, action: PayloadAction<number>) => {
      state.order = action.payload;
    },
    setEventId: (state, action: PayloadAction<string>) => {
      state.eventId = action.payload;
    },
    resetEventTemplatesWizard: () => initialState,
  },
});

export const {
  setDescription,
  setOrder,
  setEventId,
  resetEventTemplatesWizard,
} = eventTemplatesWizardSlice.actions;
export default eventTemplatesWizardSlice.reducer;
