import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

/* Invite Routes*/
export const invitesApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "invitesApi",
    baseUrl: "/api/invites",
  }),

  reducerPath: "invitesApi",
  endpoints: (builder) => ({
    createInvites: builder.mutation<
      any,
      Array<{
        personId?: string;
        name?: string;
        email?: string;
        eventId?: string;
        documentId?: string;
        companyId?: string;
        stepId?: string;
        workflowId?: string;
      }>
    >({
      query: (inviteList) => ({
        url: `/`,
        method: "POST",
        body: inviteList.map(
          ({
            personId,
            name,
            email,
            eventId,
            documentId,
            companyId,
            stepId,
            workflowId,
          }) => ({
            ...(personId && { personId }),
            ...(name && { name }),
            ...(email && { email }),
            ...(eventId && { eventId }),
            ...(documentId && { documentId }),
            ...(companyId && { companyId }),
            ...(stepId && { stepId }),
            ...(workflowId && { workflowId }),
          })
        ),
      }),
    }),
    acceptInvite: builder.mutation<any, { inviteId: string }>({
      query: ({ inviteId }) => ({
        url: `/${inviteId}/accept`,
        method: "POST",
      }),
    }),
  }),
});

/* These routes don't need to be protected by auth */
export const publicInvitesApi = createApi({
  reducerPath: "publicInvitesApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/invites" }),
  endpoints: (builder) => ({
    getInviteById: builder.query<any, { id: string }>({
      query: (args) => {
        return `/${args.id}`;
      },
    }),
  }),
});

export const { useCreateInvitesMutation, useAcceptInviteMutation } = invitesApi;
export const { useGetInviteByIdQuery } = publicInvitesApi;
