import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Role = {
  id: string;
  name: string;
};

type PersonalSettingsState = {
  role: Role;
  stateLicenseNumbers: string[];
  certifications: string[];
  bio: string;
  visibility: boolean;
  fullName: string;
  email: string;
  phone: string;
};

const initialState: PersonalSettingsState = {
  role: { id: "", name: "" },
  stateLicenseNumbers: [],
  certifications: [],
  bio: "",
  visibility: true,
  fullName: "",
  email: "",
  phone: "",
};

const personalSettingsSlice = createSlice({
  name: "personalSettings",
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<Role>) => {
      state.role = action.payload;
    },
    setStateLicenseNumbers: (state, action: PayloadAction<string[]>) => {
      state.stateLicenseNumbers = action.payload;
    },
    setCertifications: (state, action: PayloadAction<string[]>) => {
      state.certifications = action.payload;
    },
    setBio: (state, action: PayloadAction<string>) => {
      state.bio = action.payload;
    },
    setVisibility: (state, action: PayloadAction<boolean>) => {
      state.visibility = action.payload;
    },
    setFullName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
  },
});

export const {
  setRole,
  setStateLicenseNumbers,
  setCertifications,
  setBio,
  setVisibility,
  setFullName,
  setEmail,
  setPhone,
} = personalSettingsSlice.actions;
export default personalSettingsSlice.reducer;
