import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const libraryApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "libraryApi",
    baseUrl: "/api/library",
  }),
  reducerPath: "libraryApi",
  endpoints: (builder) => ({
    getFields: builder.query<any, { include?: string; limit?: number }>({
      query: (args) => {
        const params = new URLSearchParams();

        if (args.include) {
          params.append("include", args.include);
        }

        if (args.limit) {
          params.append("limit", args.limit?.toString());
        }

        return `/fields?${params.toString()}`;
      },
    }),
    getRoles: builder.query<any, { include?: string; limit?: number }>({
      query: (args) => {
        const params = new URLSearchParams();

        if (args.include) {
          params.append("include", args.include);
        }

        if (args.limit) {
          params.append("limit", args.limit?.toString());
        }

        return `/roles?${params.toString()}`;
      },
    }),
  }),
});

export const { useGetFieldsQuery, useGetRolesQuery } = libraryApi;
