import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TeamSettingsState = {
  companyName: string;
  adminEmail: string;
  stateLicenseNumbers: string[];
};

const initialState: TeamSettingsState = {
  companyName: "",
  adminEmail: "",
  stateLicenseNumbers: [],
};

const companySettingsSlice = createSlice({
  name: "companySettings",
  initialState,
  reducers: {
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload;
    },
    setAdminEmail: (state, action: PayloadAction<string>) => {
      state.adminEmail = action.payload;
    },
    setStateLicenseNumbers: (state, action: PayloadAction<string[]>) => {
      state.stateLicenseNumbers = action.payload;
    },
  },
});

export const { setCompanyName, setAdminEmail, setStateLicenseNumbers } =
  companySettingsSlice.actions;
export default companySettingsSlice.reducer;
