import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash";

type WorkflowState = {
  activeDocumentId: string;
  activeEventId: string;
};

const initialState: WorkflowState = {
  activeDocumentId: "",
  activeEventId: "",
};

const workflowsUiSlice = createSlice({
  name: "workflowsUi",
  initialState,
  reducers: {
    setActiveDocumentId: (state, action: PayloadAction<string>) => {
      state.activeDocumentId = action.payload;
    },
    setActiveEventId: (state, action: PayloadAction<string>) => {
      state.activeEventId = action.payload;
    },
    resetWorkflowState: () => initialState,
  },
});

export const { setActiveDocumentId, setActiveEventId, resetWorkflowState } =
  workflowsUiSlice.actions;
export default workflowsUiSlice.reducer;
