import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Property = {
  id: string;
  name: string;
};

type Workflow = {
  id: string;
  name: string;
  type: string;
};

type Customer = {
  id: string;
  name: string;
};

type DealsWizardState = {
  internalName: string;
  workflow: Workflow;
  property: Property;
  customers: Customer[];
};

const initialState: DealsWizardState = {
  internalName: "",
  workflow: {
    id: "",
    name: "Select a workflow",
    type: "",
  },
  property: {
    id: "",
    name: "",
  },
  customers: [{ id: "", name: "Select Customers" }],
};

const dealsWizardSlice = createSlice({
  name: "dealsWizard",
  initialState,
  reducers: {
    setInternalName: (state, action: PayloadAction<string>) => {
      state.internalName = action.payload;
    },
    setWorkflow: (state, action: PayloadAction<Workflow>) => {
      state.workflow = action.payload;
    },
    setProperty: (state, action: PayloadAction<Property>) => {
      state.property = action.payload;
    },
    setCustomers: (state, action: PayloadAction<Customer[]>) => {
      state.customers = action.payload;
    },
    resetDealsWizard: () => initialState,
  },
});

export const {
  setInternalName,
  setWorkflow,
  setProperty,
  setCustomers,
  resetDealsWizard,
} = dealsWizardSlice.actions;

export default dealsWizardSlice.reducer;
