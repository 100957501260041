import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const personsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "personsApi",
    baseUrl: "/api/persons",
  }),
  reducerPath: "personsApi",
  tagTypes: ["Persons", "Person"],
  endpoints: (builder) => ({
    getPersons: builder.query<
      any,
      {
        search?: string;
        role?: string;
        quickFilter?: string;
        include?: string;
        limit?: number;
        nextId?: string;
        hasRelationship?: string;
      }
    >({
      query: (args) => {
        // Build query parameters dynamically
        const queryParams = new URLSearchParams();
        // Append parameters if they exist
        if (args.search) queryParams.append("query", args.search);
        if (args.role) queryParams.append("role", args.role);
        if (args.quickFilter)
          queryParams.append("QUICK_FILTER", args.quickFilter);
        if (args.include) queryParams.append("include", args.include);
        if (args.limit) queryParams.append("limit", args.limit.toString());
        if (args.nextId) queryParams.append("nextId", args.nextId);
        if (args.hasRelationship !== undefined) {
          queryParams.append(
            "hasRelationship",
            args.hasRelationship.toString()
          );
        }
        return `/search?${queryParams.toString()}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Persons" }],
    }),
    getPersonById: builder.query({
      query: (id) => id,
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Person" }],
    }),
    updatePersonById: builder.mutation<any, { id: string; personData: any }>({
      query: ({ id, personData }) => ({
        url: `/${id}`,
        method: "PUT",
        body: personData,
      }),
    }),
    createRelationship: builder.mutation<any, { name: string; email: string }>({
      query: (relationship) => ({
        url: "/",
        method: "POST",
        body: relationship,
      }),
    }),
    createPersonPhoneNumber: builder.mutation<
      any,
      { id: string; phoneNumber: string }
    >({
      query: ({ id, phoneNumber }) => ({
        url: `/${id}/phone`,
        method: "POST",
        body: { phoneNumber },
      }),
    }),
    updatePersonPhoneNumber: builder.mutation<
      any,
      { id: string; phoneId: string; phoneNumber: string }
    >({
      query: ({ id, phoneId, phoneNumber }) => ({
        url: `/${id}/phone/${phoneId}`,
        method: "PUT",
        body: { phoneNumber },
      }),
    }),
    deletePersonPhoneNumber: builder.mutation<
      any,
      { id: string; phoneId: string }
    >({
      query: ({ id, phoneId }) => ({
        url: `/${id}/phone/${phoneId}`,
        method: "DELETE",
      }),
    }),
    createPersonEmail: builder.mutation<
      any,
      { id: string; emailAddress: string }
    >({
      query: ({ id, emailAddress }) => ({
        url: `/${id}/email`,
        method: "POST",
        body: { emailAddress },
      }),
    }),
    updatePersonEmail: builder.mutation<
      any,
      { id: string; emailAddress: string }
    >({
      query: ({ id, emailAddress }) => ({
        url: `/${id}/email`,
        method: "PUT",
        body: { emailAddress },
      }),
    }),
    createPersonNote: builder.mutation<any, { id: string; text: string }>({
      query: ({ id, text }) => ({
        url: `/${id}/notes`,
        method: "POST",
        body: { text },
      }),
    }),
    getPersonNotes: builder.query({
      query: (id) => {
        return `/${id}/notes`;
      },
    }),
    updateNote: builder.mutation<
      any,
      { id: string; noteId: string; text: string }
    >({
      query: ({ id, noteId, text }) => ({
        url: `/${id}/notes/${noteId}`,
        method: "PUT",
        body: { text },
      }),
    }),
    deleteNote: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/notes/${id}`,
        method: "DELETE",
      }),
    }),
    createPersonRating: builder.mutation<
      any,
      { id: string; value: number; text: string }
    >({
      query: ({ id, value, text }) => ({
        url: `/${id}/ratings`,
        method: "POST",
        body: { value, text },
      }),
    }),
    updatePersonRating: builder.mutation<
      any,
      { id: string; ratingId: string; value: number; text: string }
    >({
      query: ({ id, ratingId, value, text }) => ({
        url: `/${id}/ratings/${ratingId}`,
        method: "PUT",
        body: { value, text },
      }),
    }),
    deleteRating: builder.mutation<any, { ratingId: string }>({
      query: ({ ratingId }) => ({
        url: `/ratings/${ratingId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetPersonsQuery,
  useLazyGetPersonsQuery,
  useLazyGetPersonByIdQuery,
  useGetPersonByIdQuery,
  useUpdatePersonByIdMutation,
  useCreateRelationshipMutation,
  useCreatePersonPhoneNumberMutation,
  useUpdatePersonPhoneNumberMutation,
  useDeletePersonPhoneNumberMutation,
  useCreatePersonEmailMutation,
  useUpdatePersonEmailMutation,
  useCreatePersonNoteMutation,
  useGetPersonNotesQuery,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
  useCreatePersonRatingMutation,
  useUpdatePersonRatingMutation,
  useDeleteRatingMutation,
} = personsApi;
