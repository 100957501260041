import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const insightsUiSlice = createSlice({
  name: "insightsUi",
  initialState,
  reducers: {},
});

export const {} = insightsUiSlice.actions;
export default insightsUiSlice.reducer;
