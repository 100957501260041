import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const workflowTemplatesApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "workflowTemplatesApi",
    baseUrl: "/api/workflow-templates",
  }),
  reducerPath: "workflowTemplatesApi",
  tagTypes: ["WorkflowTemplates", "WorkflowTemplate", "WorkflowTemplateStep"],

  endpoints: (builder) => ({
    getWorkflowTemplates: builder.query<
      any,
      {
        type?: string;
        include?: string;
        search?: string;
        nextId?: string;
        limit?: number;
      }
    >({
      query: (args) => {
        // Build query parameters dynamically
        const queryParams = new URLSearchParams();
        // Append parameters if they exist
        if (args.type) queryParams.append("type", args.type); //support for type=ALL
        if (args.search) queryParams.append("search", args.search);
        if (args.include) queryParams.append("include", args.include);
        if (args.limit) queryParams.append("limit", args.limit.toString());
        if (args.nextId) queryParams.append("nextId", args.nextId);
        return `?${queryParams.toString()}`;
      },
      providesTags: [{ type: "WorkflowTemplates" }],
    }),
    getWorkflowTemplatesById: builder.query<
      any,
      { id?: string; include?: string }
    >({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}${queryParams}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "WorkflowTemplate" }],
    }),

    getWorkflowTemplateStepById: builder.query<
      any,
      { workflowId: string; stepId: string }
    >({
      query: (args) => {
        return `/${args.workflowId}/step-templates/${args.stepId}`;
      },
      providesTags: [{ type: "WorkflowTemplateStep" }],
    }),
    createWorkflowTemplate: builder.mutation<
      any,
      { name: string; type: string; dueDate: string; active: boolean }
    >({
      query: ({ name, type, dueDate, active }) => ({
        url: "/",
        method: "POST",
        body: { name, type, dueDate, active },
      }),
    }),
    updateWorkflowTemplate: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    createStepTemplate: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/step-templates`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetWorkflowTemplatesQuery,
  useGetWorkflowTemplatesByIdQuery,
  useGetWorkflowTemplateStepByIdQuery,
  useCreateWorkflowTemplateMutation,
  useUpdateWorkflowTemplateMutation,
  useCreateStepTemplateMutation,
} = workflowTemplatesApi;
