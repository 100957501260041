import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "./Icon";
import { Flex } from "./Flex";
import React from "react";

type DropdownItem = {
  text: string | React.ReactNode;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  href?: string;
};

type Theme = "blue" | "white";

type DropdownProps = {
  items: DropdownItem[];
  buttonText: string;
  buttonColor?: Theme;
  icon?: string;
  iconOnRight?: boolean;
  size?: "sm" | "md";
  className?: string;
};

const Dropdown = ({
  items,
  buttonText,
  buttonColor = "white",
  icon = "",
  iconOnRight = false,
  size = "md",
  className = "",
}: DropdownProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button
          className={`outline-none ${
            size === "sm" ? "px-2.5 py-1.5" : "px-4 py-2"
          } rounded-md text-sm font-medium ${
            buttonColor === "blue"
              ? "bg-primary-blue text-zinc-50 hover:bg-primary-blue/80"
              : buttonColor === "white"
              ? "border border-zinc-200 bg-white text-zinc-950 hover:bg-zinc-100"
              : "bg-white text-zinc-950 hover:bg-zinc-100"
          } ${size === "sm" ? "text-xs" : ""} ${className}`}
        >
          <Flex
            align="center"
            justify="between"
            gap={1}
            className={`${iconOnRight ? "flex-row-reverse" : ""}`}
          >
            {icon && (
              <Icon name={icon} strokeWidth={2} size={size === "sm" ? 3 : 4} />
            )}
            {buttonText}
          </Flex>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="z-10 w-56">
        {items.map((item, index) =>
          item.href ? (
            <DropdownMenuItem asChild key={index}>
              <a
                href={item.href}
                className={`block px-4 py-2 ${
                  size === "sm" ? "text-xs" : "text-sm"
                } text-zinc-950 rounded-md hover:bg-zinc-100`}
              >
                {item.text}
              </a>
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem asChild key={index}>
              <button
                type="button"
                className={`w-full px-4 py-2 text-left ${
                  size === "sm" ? "text-xs" : "text-sm"
                } text-zinc-950 rounded-md hover:bg-zinc-100`}
                onClick={item.onClick}
              >
                {item.text}
              </button>
            </DropdownMenuItem>
          )
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default Dropdown;
