import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const personsUiSlice = createSlice({
  name: "personsUi",
  initialState,
  reducers: {},
});

export const {} = personsUiSlice.actions;
export default personsUiSlice.reducer;
