import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const propertiesUiSlice = createSlice({
  name: "propertiesUi",
  initialState,
  reducers: {},
});

export const {} = propertiesUiSlice.actions;
export default propertiesUiSlice.reducer;
