import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type OnboardingState = {
  name: string;
  email: string;
  phoneNumber: string;
  role: { id: string; name: string };
  hasCompany: boolean;
  company: {
    name: string;
    licenseNumbers: string; // TODO change this to list
  };
  teamEmails: string;
};

const initialState: OnboardingState = {
  name: "",
  email: "",
  phoneNumber: "",
  role: { id: "", name: "" },
  hasCompany: false,
  company: {
    name: "",
    licenseNumbers: "", // TODO change this to list
  },
  teamEmails: "",
};

type CompanyKeys = keyof OnboardingState["company"];

const onboardingUiSlice = createSlice({
  name: "onboardingUi",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setRole: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const { id, name } = action.payload;
      state.role = { id, name };
    },
    setHasCompany: (state, action: PayloadAction<boolean>) => {
      state.hasCompany = action.payload;
    },
    setWorkspace: (
      state,
      action: PayloadAction<{ key: CompanyKeys; value: any }>
    ) => {
      const { key, value } = action.payload;
      state.company[key] = value;
    },
    setTeamEmails: (state, action: PayloadAction<string>) => {
      state.teamEmails = action.payload;
    },
  },
});

export const {
  setName,
  setEmail,
  setPhoneNumber,
  setRole,
  setWorkspace,
  setHasCompany,
  setTeamEmails,
} = onboardingUiSlice.actions;
export default onboardingUiSlice.reducer;
