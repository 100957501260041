import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const invitesUiSlice = createSlice({
  name: "invitesUi",
  initialState,
  reducers: {},
});

export const {} = invitesUiSlice.actions;
export default invitesUiSlice.reducer;
