import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Address = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

type PropertiesWizardState = {
  internalName: string;
  summary: string;
  address: Address;
};

const initialAddress: Address = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  postalCode: "",
  country: "",
};

const initialState: PropertiesWizardState = {
  internalName: "",
  summary: "",
  address: initialAddress,
};

const propertiesWizardSlice = createSlice({
  name: "propertiesWizard",
  initialState,
  reducers: {
    setInternalName: (state, action: PayloadAction<string>) => {
      state.internalName = action.payload;
    },
    setAddress: (state, action: PayloadAction<Partial<Address>>) => {
      state.address = { ...state.address, ...action.payload };
    },
    setSummary: (state, action: PayloadAction<string>) => {
      state.summary = action.payload;
    },
    resetPropertiesWizard: () => initialState,
  },
});

export const {
  setInternalName,
  setAddress,
  setSummary,
  resetPropertiesWizard,
} = propertiesWizardSlice.actions;
export default propertiesWizardSlice.reducer;
