import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";
export const signaturesApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "signaturesApi",
    baseUrl: "/api/signatures",
  }),
  reducerPath: "signaturesApi",
  tagTypes: ["Signatures"],
  endpoints: (builder) => ({
    getSignatures: builder.query<
      any,
      { include?: string; invitationId?: string; personId?: string }
    >({
      query: ({ include, invitationId, personId }) => {
        // Build query parameters dynamically
        const queryParams = new URLSearchParams();
        if (include) queryParams.append("include", include);
        if (invitationId) queryParams.append("invitationId", invitationId);
        if (personId) queryParams.append("personId", personId);

        return `?${queryParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: any) => ({ type: "Signatures", id } as const)
              ),
              "Signatures",
            ]
          : ["Signatures"],
    }),
    getSignatureById: builder.query<any, { id?: string; include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}${queryParams}`;
      },
      providesTags: ({ id }) => [{ type: "Signatures", id }],
    }),
    createSignature: builder.mutation<
      any,
      { body: any; invitationId?: string; personId?: string }
    >({
      query: ({ body, invitationId, personId }) => {
        // Build query parameters dynamically
        const queryParams = new URLSearchParams();
        if (invitationId) queryParams.append("invitationId", invitationId);
        if (personId) queryParams.append("personId", personId);

        return {
          url: `?${queryParams.toString()}`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: [{ type: "Signatures" }],
    }),
    updateSignature: builder.mutation<
      any,
      { id: string; body: any; invitationId?: string; personId?: string }
    >({
      query: ({ id, body, invitationId, personId }) => {
        // Build query parameters dynamically
        const queryParams = new URLSearchParams();
        if (invitationId) queryParams.append("invitationId", invitationId);
        if (personId) queryParams.append("personId", personId);

        return {
          url: `/${id}?${queryParams.toString()}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: [{ type: "Signatures" }],
    }),
    deleteSignature: builder.mutation<any, { id: string }>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Signatures" }],
    }),
  }),
});

export const {
  useGetSignaturesQuery,
  useGetSignatureByIdQuery,
  useCreateSignatureMutation,
  useUpdateSignatureMutation,
  useDeleteSignatureMutation,
} = signaturesApi;
