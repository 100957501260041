import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { supabase } from "@/utilities/supabase";

interface CreateBaseApiProps {
  reducerPath: string;
  baseUrl: string;
}

export const createBaseApiWithAuth = ({
  reducerPath,
  baseUrl,
}: CreateBaseApiProps) => {
  return {
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl,
      prepareHeaders: async (headers, { extra }: any) => {
        const meta: any = extra?.meta;

        /*
          Check if the request should skip authorization.
          Even though this middleware is used everywhere, this is JUST for 
          docs/signatures (for anonymous signing rn). If we start adding to 
          this, we should consider breaking out different baseApis 
        */
        const isAuthSkipped = meta?.personId && meta?.invitationId;

        if (!isAuthSkipped) {
          // Retrieve the session from Supabase
          const {
            data: { session },
          }: any = await supabase.auth.getSession();

          if (session?.access_token) {
            headers.set("authorization", `Bearer ${session.access_token}`);
          }
        }

        return headers;
      },
    }),
  };
};
