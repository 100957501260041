import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import { isNullOrUndefined } from "@/utilities";
import { setAuthUiState } from "@/slices/auth/authUi";
import { useAppDispatch } from "@/hooks/redux";
import { useLazyGetUserByIdQuery } from "@/slices/users/usersApi";

const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL;
const SUPABASE_ANON_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY;

const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

// Utility function to check if route is exempt from auth
const isRouteExemptFromAuth = (
  pathname: string,
  searchParams: URLSearchParams
) => {
  if (pathname?.toLowerCase()?.includes("documents")) {
    const personId = searchParams.get("personId");
    const invitationId = searchParams.get("invitationId");
    return personId && invitationId; // Return true if both are present
  }
  return false;
};

export const Auth = ({ children }: any) => {
  const [sessionState, setSessionState]: any = useState(null);
  const dispatch = useAppDispatch();
  const [triggerGetUserById] = useLazyGetUserByIdQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isExempt = isRouteExemptFromAuth(location.pathname, searchParams);

  const handleSessionChange = async (session: any) => {
    setSessionState(session);

    if (!session || isNullOrUndefined(session)) {
      if (!isExempt) {
        navigate("/login");
      }
    } else {
      const { data: userRes } = await triggerGetUserById(
        session?.user?.id,
        false
      );
      dispatch(setAuthUiState(userRes));
    }
  };

  useEffect(() => {
    supabase.auth
      .getSession()
      .then(({ data: { session } }) => handleSessionChange(session));

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      handleSessionChange(session);
    });

    return () => subscription.unsubscribe();
  }, [location]);

  return sessionState || isExempt ? children : null;
};
