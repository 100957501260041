// src/features/deals/dealsUISlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDealsPanelOpen: false,
  dealsFilter: "active", // Example filter state
};

const dealsUISlice = createSlice({
  name: "dealsUI",
  initialState,
  reducers: {
    toggleDealsPanel: (state) => {
      state.isDealsPanelOpen = !state.isDealsPanelOpen;
    },
    setDealsFilter: (state, action) => {
      state.dealsFilter = action.payload;
    },
  },
});

export const { toggleDealsPanel, setDealsFilter } = dealsUISlice.actions;
export default dealsUISlice.reducer;
