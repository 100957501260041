import * as Sentry from "@sentry/react";

const SENTRY_ENABLED = import.meta.env.VITE_SENTRY_ENABLED;

export const SentryWrapper = ({ children }: any) => {
  if (SENTRY_ENABLED === "true") {
    Sentry.init({
      dsn: "https://f5034162b9420499d285b1a88e9ca966@o4504696167202816.ingest.us.sentry.io/4507177185771520",
      integrations: [],
    });
  }

  return children;
};

export default SentryWrapper;
