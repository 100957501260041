import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

interface AvatarWithFallbackProps {
  image?: string;
  emptyImageText?: string;
  className?: string;
  imageClassName?: string;
}

export const AvatarWithFallback: React.FC<AvatarWithFallbackProps> = ({
  image,
  emptyImageText,
  className = "",
  imageClassName = "",
}) => {
  return image || emptyImageText ? (
    <Avatar className={`h-9 w-9 !outline-none ${className}`}>
      {image ? (
        <AvatarImage
          src={image}
          alt={emptyImageText || ""}
          className={`!outline-none ${
            !image
              ? "bg-[linear-gradient(180deg,#EBEBEB_0%,#DED9D9_100%)] text-zinc-950 p-1"
              : ""
          } ${imageClassName}`}
        />
      ) : (
        <AvatarFallback
          className={`!outline-none ${
            !image
              ? "bg-[linear-gradient(180deg,#EBEBEB_0%,#DED9D9_100%)] text-zinc-950 p-1"
              : ""
          } ${imageClassName}`}
        >
          {emptyImageText}
        </AvatarFallback>
      )}
    </Avatar>
  ) : null;
};
