import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

/* User Routes*/
export const usersApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "usersApi",
    baseUrl: "/api/users",
  }),
  reducerPath: "usersApi",
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUserById: builder.query<any, string>({
      query: (id) => `/${id}`,
      keepUnusedDataFor: 0,
      providesTags: [{ type: "User" }],
    }),
    updateUserById: builder.mutation<any, { id: string; userData: any }>({
      query: ({ id, userData }) => ({
        url: `/${id}`,
        method: "PUT",
        body: userData,
      }),
    }),
    createPerson: builder.mutation<any, { userId: string; newPerson: any }>({
      query: ({ userId, newPerson }) => ({
        url: `/${userId}/persons`,
        method: "POST",
        body: newPerson,
      }),
    }),
  }),
});

/* These routes don't need to be protected by auth */
export const publicUsersApi = createApi({
  reducerPath: "publicUsersApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/users" }),
  endpoints: (builder) => ({
    verifyOrCreateUser: builder.mutation<any, { userId: string }>({
      query: ({ userId }) => ({
        url: "verify-or-create",
        method: "POST",
        body: { userId },
      }),
    }),
    createUser: builder.mutation<any, { userData: any }>({
      query: ({ userData }) => ({
        url: "/",
        method: "POST",
        body: userData,
      }),
    }),
  }),
});

export const {
  useGetUserByIdQuery,
  useUpdateUserByIdMutation,
  useLazyGetUserByIdQuery,
  useCreatePersonMutation,
} = usersApi;

export const { useVerifyOrCreateUserMutation, useCreateUserMutation } =
  publicUsersApi;
