import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const notificationsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "notificationsApi",
    baseUrl: "/api/notifications",
  }),
  reducerPath: "notificationsApi",
  tagTypes: ["Notifications"],
  endpoints: (builder) => ({
    getNotifications: builder.query<
      any,
      { search?: string; nextId?: string; limit?: number }
    >({
      query: (args) => {
        // Build query parameters dynamically
        const queryParams = new URLSearchParams();
        // Append parameters if they exist
        if (args.search) queryParams.append("search", args.search);
        if (args.limit) queryParams.append("limit", args.limit.toString());
        if (args.nextId) queryParams.append("nextId", args.nextId);
        return `?${queryParams.toString()}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Notifications" }],
    }),
    getNotificationById: builder.query<any, { id: string }>({
      query: (id) => {
        return `/${id}`;
      },
    }),
    updateNotification: builder.mutation<
      any,
      { id: string; isViewed: boolean }
    >({
      query: ({ id, isViewed }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: { isViewed },
      }),
    }),
    deleteNotification: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
} = notificationsApi;
