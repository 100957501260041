import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TeamMember = {
  name: string;
  role: string;
  id: string;
  status: string;
};

type TeamSettingsState = {
  teamMembers: TeamMember[];
};

const initialState: TeamSettingsState = {
  teamMembers: [],
};

const teamSettingsSlice = createSlice({
  name: "teamSettings",
  initialState,
  reducers: {
    setTeamMembers: (state, action: PayloadAction<TeamMember[]>) => {
      state.teamMembers = action.payload;
    },
    addTeamMember: (state, action: PayloadAction<TeamMember>) => {
      state.teamMembers.push(action.payload);
    },
    updateTeamMember: (
      state,
      action: PayloadAction<{ id: string; updatedMember: TeamMember }>
    ) => {
      const { id, updatedMember } = action.payload;
      const index = state.teamMembers.findIndex((member) => member.id === id);
      if (index !== -1) {
        state.teamMembers[index] = updatedMember;
      }
    },
    removeTeamMember: (state, action: PayloadAction<string>) => {
      state.teamMembers = state.teamMembers.filter(
        (member) => member.id !== action.payload
      );
    },
  },
});

export const {
  setTeamMembers,
  addTeamMember,
  updateTeamMember,
  removeTeamMember,
} = teamSettingsSlice.actions;
export default teamSettingsSlice.reducer;
