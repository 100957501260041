import {
  BuildingOfficeIcon,
  BanknotesIcon,
  BookmarkIcon,
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  EllipsisVerticalIcon,
  InformationCircleIcon,
  PlusIcon,
  UserCircleIcon,
  XMarkIcon,
  BriefcaseIcon,
  MagnifyingGlassIcon,
  RectangleStackIcon,
  MinusIcon,
  FolderIcon,
  CalendarDaysIcon,
  ChatBubbleBottomCenterIcon,
  HomeModernIcon,
  BoltIcon,
  UsersIcon,
  ChatBubbleOvalLeftIcon,
  Cog6ToothIcon,
  QuestionMarkCircleIcon,
  HomeIcon,
  LinkIcon,
  DocumentTextIcon,
  IdentificationIcon,
  ChartBarSquareIcon,
  StarIcon,
  PencilIcon,
  AdjustmentsHorizontalIcon,
  Bars3BottomRightIcon,
  TrashIcon,
  ArrowRightIcon,
  QueueListIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";

import { StarIcon as StarIconFilled } from "@heroicons/react/24/solid";

const icons: any = {
  buildingOffice: BuildingOfficeIcon,
  banknotes: BanknotesIcon,
  bookmark: BookmarkIcon,
  calendar: CalendarIcon,
  checkCircle: CheckCircleIcon,
  check: CheckIcon,
  chevronRight: ChevronRightIcon,
  chevronLeft: ChevronLeftIcon,
  chevronDown: ChevronDownIcon,
  chevronUpDown: ChevronUpDownIcon,
  ellipsisVertical: EllipsisVerticalIcon,
  informationCircle: InformationCircleIcon,
  plus: PlusIcon,
  userCircle: UserCircleIcon,
  xMark: XMarkIcon,
  briefcase: BriefcaseIcon,
  magnifyingGlass: MagnifyingGlassIcon,
  rectangleStack: RectangleStackIcon,
  minus: MinusIcon,
  folder: FolderIcon,
  document: DocumentTextIcon,
  calendarDays: CalendarDaysIcon,
  chatBubbleBottomCenter: ChatBubbleBottomCenterIcon,
  homeModern: HomeModernIcon,
  bolt: BoltIcon,
  users: UsersIcon,
  chatBubbleOvalLeft: ChatBubbleOvalLeftIcon,
  cog6Tooth: Cog6ToothIcon,
  questionMarkCircle: QuestionMarkCircleIcon,
  home: HomeIcon,
  link: LinkIcon,
  documentText: DocumentTextIcon,
  identification: IdentificationIcon,
  chartBarSquare: ChartBarSquareIcon,
  star: StarIcon,
  starFilled: StarIconFilled,
  pencil: PencilIcon,
  adjustmentsHorizontal: AdjustmentsHorizontalIcon,
  bars3BottomRight: Bars3BottomRightIcon,
  trash: TrashIcon,
  arrowRight: ArrowRightIcon,
  queueList: QueueListIcon,
  arrowLeft: ArrowLeftIcon,
};

interface IconProps {
  name: string;
  className?: string;
  size?: number;
  strokeWidth?: number;
  onClick?: () => void;
}

export const Icon: React.FC<IconProps> = ({
  name,
  className,
  size = 4,
  strokeWidth = 1,
  onClick,
}) => {
  const IconComponent = icons[name] as React.ElementType;
  if (!IconComponent) return null;
  return (
    <div
      onClick={onClick}
      style={{
        height: `${size * 4}px`,
        width: `${size * 4}px`,
        cursor: onClick ? "pointer" : "default",
      }}
    >
      <IconComponent className={className} style={{ strokeWidth }} />
    </div>
  );
};
