import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  name: "",
  email: "",
  person: {},
};

const authUiSlice = createSlice({
  name: "authUi",
  initialState,
  reducers: {
    setAuthUiState(state, action: PayloadAction<typeof initialState>) {
      return { ...state, ...action.payload };
    },
    resetAuthUiState() {
      return initialState;
    },
  },
});

export const { setAuthUiState, resetAuthUiState } = authUiSlice.actions;
export default authUiSlice.reducer;
