import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const mapsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "mapsApi",
    baseUrl: "/api/maps",
  }),
  reducerPath: "mapsApi",
  tagTypes: ["Autocomplete"],
  endpoints: (builder) => ({
    getAutocomplete: builder.query<
      any,
      { input?: string; lat?: string; lng: number; radius: number }
    >({
      query: (args) => {
        return `autocomplete?input=${args.input}&lat=${args.lat}&lng=${args.lng}&radius=${args.radius}`;
      },
      //   providesTags: (result) =>
      //     result
      //       ? [
      //           ...result.predictions.map(
      //             ({ place_id }: any) =>
      //               ({ type: "Autocomplete", id: place_id } as const)
      //           ),
      //           "Autocomplete",
      //         ]pla
      //       : ["Autocomplete"],
    }),
    getPlaceDetails: builder.query<any, { placeId: string }>({
      query: ({ placeId }) => `details/${placeId}`,
    }),
  }),
});

export const { useGetAutocompleteQuery, useGetPlaceDetailsQuery } = mapsApi;
