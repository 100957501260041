import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const stepTemplatesApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "stepTemplatesApi",
    baseUrl: "/api/step-templates",
  }),
  reducerPath: "stepTemplatesApi",
  endpoints: (builder) => ({
    createEventTemplate: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/event-templates`,
        method: "POST",
        body: data,
      }),
    }),
    createDocumentTemplate: builder.mutation<
      any,
      { stepTemplateId: string; documentTemplateId: any }
    >({
      query: ({ stepTemplateId, documentTemplateId }) => ({
        url: `/${stepTemplateId}/document-templates/${documentTemplateId}`,
        method: "POST",
        body: { stepTemplateId, documentTemplateId },
      }),
    }),
    deleteStepTemplate: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreateEventTemplateMutation,
  useCreateDocumentTemplateMutation,
  useDeleteStepTemplateMutation,
} = stepTemplatesApi;
