import { Header } from "@/components/Header";
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import { useIsMobile } from "@/hooks/use-mobile";
import { Outlet } from "react-router-dom";
import { SettingsSidebar } from "./SettingsSidebar";

export const SettingsLayout = () => {
  const isMobile = useIsMobile();

  return (
    <SidebarProvider>
      <div className="flex flex-col w-full min-h-screen max-w-[1120px] mx-auto">
        {/* Header */}
        <Header
          title="Settings"
          subtitle="Manage your account and workspace settings."
          hasSeparator={true}
          actions={
            <SidebarTrigger key="sidebar-trigger" className="md:hidden block" />
          }
        />

        {/* Main Content */}
        <div className="flex flex-1">
          <SettingsSidebar isMobile={isMobile} />
          <main className="flex-1 p-6">
            <Outlet />
          </main>
        </div>
      </div>
    </SidebarProvider>
  );
};
