import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import { PlatformWithTopNav as PlatformLayoutWithTopNav } from "./layouts/PlatformWithTopNav";
import { Platform as PlatformLayout } from "./layouts/Platform";
import { Loader } from "@/components/Loader";
import { SettingsLayout } from "@/pages/Settings/SettingsLayout";

// TODO make this better
function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>Error!</div>;
}

/* Helpers */
const smartFieldsRoute: RouteObject = {
  path: "deals/:id/smart-fields",
  lazy: async () => {
    const { SmartFields } = await import("@/pages/SmartFields/SmartFields");
    return {
      Component: (props: any): JSX.Element => <SmartFields {...props} />,
    };
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const { DynamicFormFields } = await import(
          "@/pages/SmartFields/DynamicFormFields"
        );
        return {
          Component: (props: any): JSX.Element => (
            <DynamicFormFields {...props} />
          ),
        };
      },
    },
    {
      path: ":category",
      lazy: async () => {
        const { DynamicFormFields } = await import(
          "@/pages/SmartFields/DynamicFormFields"
        );
        return {
          Component: (props: any): JSX.Element => (
            <DynamicFormFields {...props} />
          ),
        };
      },
    },
  ],
};

const workflowSidebarRoute = (basePath: string) => ({
  path: `${basePath}/:id`,
  lazy: async () => {
    let { Workflow } = await import("@/pages/Workflow/Workflow");
    return { Component: (props: any) => <Workflow {...props} /> };
  },
});
const newWorkflowSidebarRoute = (basePath: string) => ({
  path: `${basePath}/:id/steps/:stepId`,
  lazy: async () => {
    let { Workflow } = await import("@/pages/Workflow/Workflow");
    return { Component: (props: any) => <Workflow {...props} /> };
  },
});

const generateDocumentRoute = (
  basePath: string,
  mode: "VIEW" | "EDIT" | "SIGN"
) => ({
  path: `${basePath}/:id/${mode}`,
  lazy: async () => {
    let { Document } = await import("@/pages/Document/Document");
    return { Component: (props: any) => <Document {...props} mode={mode} /> };
  },
  children: [
    {
      path: "",
      lazy: async () => {
        const isDocumentTemplate = basePath.startsWith("document-templates");
        if (isDocumentTemplate) {
          let { DocumentTemplateSidebarMain } = await import(
            "@/components/TipTap/components/Sidebars/DocumentTemplate/DocumentTemplateSidebarMain"
          );
          return {
            Component: (props: any) => (
              <DocumentTemplateSidebarMain {...props} />
            ),
          };
        } else {
          let { DocumentSidebarMain } = await import(
            "@/components/TipTap/components/Sidebars/Document/DocumentSidebarMain"
          );
          return {
            Component: (props: any) => <DocumentSidebarMain {...props} />,
          };
        }
      },
    },
    {
      path: "smartfield/create",
      index: true,
      lazy: async () => {
        let { DocumentTemplateSidebarCreate } = await import(
          "@/components/TipTap/components/Sidebars/DocumentTemplate/DocumentTemplateSidebarCreate"
        );
        return {
          Component: (props: any) => (
            <DocumentTemplateSidebarCreate {...props} />
          ),
        };
      },
    },
  ],
});

const router = createBrowserRouter([
  /*
    Protected Routes, with TopNav
  */
  {
    path: "/",
    element: <PlatformLayoutWithTopNav />,
    errorElement: <div>Error</div>,
    children: [
      ...["/", "/dashboard"].map((path) => ({
        index: true,
        path: path,
        lazy: async () => {
          let { Dashboard } = await import("@/pages/Dashboard/Dashboard");
          return { Component: Dashboard };
        },
      })),
      ...[
        {
          path: "relationships",
          lazy: async () => {
            let { Relationships } = await import(
              "@/pages/Relationships/Relationships"
            );
            return { Component: Relationships };
          },
        },
        {
          path: "deals",
          lazy: async () => {
            let { Deals } = await import("@/pages/Deals/Deals");
            return { Component: Deals };
          },
        },
        {
          path: "properties",
          lazy: async () => {
            let { Properties } = await import("@/pages/Properties/Properties");
            return { Component: Properties };
          },
        },
        {
          path: "templates",
          lazy: async () => {
            let { Templates } = await import("@/pages/Templates/Templates");
            return { Component: Templates };
          },
          children: [
            {
              index: true,
              lazy: async () => {
                let { Workflows } = await import(
                  "@/pages/Templates/components/Workflows"
                );
                return { Component: Workflows };
              },
            },
            {
              path: "workflows",
              lazy: async () => {
                let { Workflows } = await import(
                  "@/pages/Templates/components/Workflows"
                );
                return { Component: Workflows };
              },
            },
            {
              path: "documents",
              lazy: async () => {
                let { Documents } = await import(
                  "@/pages/Templates/components/Documents"
                );
                return { Component: Documents };
              },
            },
          ],
        },
        {
          path: "documents/",
          lazy: async () => {
            let { Documents } = await import("@/pages/Documents/Documents");
            return { Component: Documents };
          },
        },
        {
          path: "schedule",
          lazy: async () => {
            let { Schedule } = await import("@/pages/Schedule/Schedule");
            return { Component: Schedule };
          },
        },
        {
          path: "events/:eventId",
          lazy: async () => {
            let { Event } = await import("@/pages/Event/Event");
            return { Component: Event };
          },
        },
        {
          path: "settings",
          element: <SettingsLayout />, // Apply the layout here
          children: [
            {
              path: "contact-info",
              lazy: async () => {
                let { ContactInfoSettings } = await import(
                  "@/pages/Settings/ContactInfoSettings"
                );
                return { Component: ContactInfoSettings };
              },
            },
            {
              path: "profile",
              lazy: async () => {
                let { ProfileSettings } = await import(
                  "@/pages/Settings/ProfileSettings"
                );
                return { Component: ProfileSettings };
              },
            },
            {
              path: "workspace",
              lazy: async () => {
                let { WorkspaceSettings } = await import(
                  "@/pages/Settings/WorkspaceSettings"
                );
                return { Component: WorkspaceSettings };
              },
            },
            {
              path: "members",
              lazy: async () => {
                let { TeamSettings } = await import(
                  "@/pages/Settings/TeamSettings"
                );
                return { Component: TeamSettings };
              },
            },
          ],
        },
        {
          path: "search",
          lazy: async () => {
            let { Search } = await import("@/pages/Search/Search");
            return { Component: Search };
          },
        },
        {
          path: "notifications",
          lazy: async () => {
            let { Notifications } = await import(
              "@/pages/Notifications/Notifications"
            );
            return { Component: Notifications };
          },
        },
        {
          path: "components",
          lazy: async () => {
            let { Components } = await import("@/pages/Components/Components");
            return { Component: Components };
          },
        },
      ],
    ],
  },
  /*
    Protected Routes without sidebar
  */
  {
    path: "/",
    element: <PlatformLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "properties/:id",
        lazy: async () => {
          let { Property } = await import("@/pages/Property/Property");
          return { Component: Property };
        },
      },
      {
        path: "deals/:id",
        lazy: async () => {
          let { Deal } = await import("@/pages/Deal/Deal");
          return { Component: Deal };
        },
      },
      {
        path: "deals/:id/tours",
        lazy: async () => {
          let { Tour } = await import("@/pages/Tour/Tour");
          return { Component: Tour };
        },
      },
      {
        path: "deals/:id/smart-fields",
        lazy: async () => {
          let { SmartFields } = await import("@/pages/SmartFields/SmartFields");
          return { Component: SmartFields };
        },
      },
      {
        path: "workflows/:id",
        lazy: async () => {
          let { Workflow } = await import("@/pages/Workflow/Workflow");
          return { Component: Workflow };
        },
      },
      workflowSidebarRoute("workflows"),
      workflowSidebarRoute("workflows/templates"),
      newWorkflowSidebarRoute("workflows"),
      newWorkflowSidebarRoute("workflows/templates"),
      // These are all about the same, so we made a helper function for less code
      generateDocumentRoute("documents", "EDIT"),
      generateDocumentRoute("documents", "VIEW"),
      generateDocumentRoute("documents", "SIGN"),
      generateDocumentRoute("document-templates", "VIEW"),
      generateDocumentRoute("document-templates", "EDIT"),
      smartFieldsRoute,
    ],
  },
  /*
    Public Routes
  */
  {
    path: "/login",
    errorElement: <div>Error</div>,
    children: [
      {
        index: true,
        lazy: async () => {
          let { Login } = await import("@/pages/Login/Login");
          return { Component: Login };
        },
      },
      {
        path: "check",
        lazy: async () => {
          let { Check } = await import("@/pages/Login/Check");
          return { Component: Check };
        },
      },
    ],
  },
  {
    path: "/onboarding",
    errorElement: <div>Error</div>,
    children: [
      ...["", "sign-up"].map((path) => ({
        index: path === "",
        path: path,
        lazy: async () => {
          let { SignUp } = await import("@/pages/Onboarding/SignUp");
          return { Component: SignUp };
        },
      })),
      {
        path: "personal",
        lazy: async () => {
          let { Personal } = await import("@/pages/Onboarding/Personal");
          return { Component: Personal };
        },
      },
      {
        path: "workspace",
        lazy: async () => {
          let { Workspace } = await import("@/pages/Onboarding/Workspace");
          return { Component: Workspace };
        },
      },
      {
        path: "team",
        lazy: async () => {
          let { Team } = await import("@/pages/Onboarding/Team");
          return { Component: Team };
        },
      },
      {
        path: "invite/:id",
        lazy: async () => {
          let { InviteOnboarding } = await import(
            "@/pages/Onboarding/InviteOnboarding"
          );
          return { Component: InviteOnboarding };
        },
      },
    ],
  },
  {
    path: "/invite",
    errorElement: <div>Error</div>,
    children: [
      {
        path: ":id",
        lazy: async () => {
          let { Invite } = await import("@/pages/Invite/Invite");
          return { Component: Invite };
        },
      },
    ],
  },
  {
    path: "/confirmation/document",
    errorElement: <div>Error</div>,
    children: [
      {
        path: "signed",
        lazy: async () => {
          let { DocumentSigned } = await import(
            "@/pages/Confirmation/DocumentSigned"
          );
          return { Component: DocumentSigned };
        },
      },
      {
        path: "sent",
        lazy: async () => {
          let { DocumentSent } = await import(
            "@/pages/Confirmation/DocumentSent"
          );
          return { Component: DocumentSent };
        },
      },
    ],
  },
]);

export const Routes = () => {
  return (
    <RouterProvider
      router={router}
      fallbackElement={<Loader title="loading..." isFullPage />}
    />
  );
};
