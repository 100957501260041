import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const insightsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "insightsApi",
    baseUrl: "/api/insights",
  }),
  reducerPath: "insightsApi",
  endpoints: (builder) => ({
    getInsights: builder.query<any, {}>({
      query: () => {
        return ``;
      },
    }),
  }),
});

export const { useGetInsightsQuery } = insightsApi;
