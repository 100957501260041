import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const eventsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "eventsApi",
    baseUrl: "/api/events",
  }),
  reducerPath: "eventsApi",
  tagTypes: ["Event"],
  endpoints: (builder) => ({
    getEvents: builder.query<any, { include?: string; limit?: number }>({
      query: (args) => {
        const params = new URLSearchParams();

        if (args.include) {
          params.append("include", args.include);
        }

        if (args.limit) {
          params.append("limit", args.limit?.toString());
        }

        return `?${params.toString()}`;
      },
    }),
    getEventsById: builder.query<any, { id: string; include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}${queryParams}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Event" }],
    }),
    updateEvent: builder.mutation<any, { id: string; status: string }>({
      query: ({ id, status }) => ({
        url: `/${id}`,
        method: "PUT",
        body: { status },
      }),
    }),
    addEventParticipants: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/participants`,
        method: "POST",
        body: { data },
      }),
    }),
    removeEventParticipants: builder.mutation<
      any,
      { id: string; personId: string }
    >({
      query: ({ id, personId }) => ({
        url: `/${id}/participants/${personId}`,
        method: "DELETE",
      }),
    }),
    deleteEvent: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventsByIdQuery,
  useUpdateEventMutation,
  useAddEventParticipantsMutation,
  useRemoveEventParticipantsMutation,
  useDeleteEventMutation,
} = eventsApi;
