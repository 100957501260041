import React from "react";

interface FlexProps {
  direction?: "row" | "col";
  align?: "start" | "center" | "end" | "stretch" | "baseline";
  justify?: "start" | "center" | "end" | "between" | "around";
  gap?: number;
  children: React.ReactNode;
  className?: string;
}

export const Flex: React.FC<FlexProps> = ({
  direction = "row",
  align = "start",
  justify = "start",
  gap = 0,
  children,
  className,
}) => {
  const directionClasses = {
    row: "flex-row",
    col: "flex-col",
  };
  const alignClasses = {
    start: "items-start",
    center: "items-center",
    end: "items-end",
    stretch: "items-stretch",
    baseline: "items-baseline",
  };
  const justifyClasses = {
    start: "justify-start",
    center: "justify-center",
    end: "justify-end",
    between: "justify-between",
    around: "justify-around",
  };

  const flexClasses = `flex ${directionClasses[direction]} ${
    alignClasses[align]
  } ${justifyClasses[justify]} gap-${gap} ${className || ""}`;

  return <div className={flexClasses}>{children}</div>;
};
