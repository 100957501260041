import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { dealsApi } from "@/slices/deals/dealsApi";
import dealsUiReducer from "@/slices/deals/dealsUi";
import dealsWizardReducer from "@/slices/deals/dealsWizard";

import { propertiesApi } from "@/slices/properties/propertiesApi";
import propertiesUiReducer from "@/slices/properties/propertiesUi";
import propertiesWizardReducer from "@/slices/properties/propertiesWizard";
import { personsApi } from "@/slices/persons/personsApi";
import personsUiReducer from "@/slices/persons/personsUi";
import { eventsApi } from "@/slices/events/eventsApi";
import eventsUiReducer from "@/slices/events/eventsUi";
import { usersApi, publicUsersApi } from "@/slices/users/usersApi";
import { insightsApi } from "@/slices/insights/insightsApi";
import insightsUiReducer from "@/slices/insights/insightsUi";

import { invitesApi, publicInvitesApi } from "@/slices/invites/invitesApi";
import invitesUiReducer from "@/slices/invites/invitesUi";

import { onboardingApi } from "@/slices/onboarding/onboardingApi";
import onboardingUiReducer from "@/slices/onboarding/onboardingUi";

import { workflowTemplatesApi } from "./slices/workflows/workflowTemplatesApi";
import { workflowsApi } from "./slices/workflows/workflowsApi";
import { stepTemplatesApi } from "./slices/workflows/stepTemplatesApi";
import { eventTemplatesApi } from "./slices/eventTemplates/eventTemplatesApi";
import { stepsApi } from "./slices/steps/stepsApi";
import workflowsUI from "./slices/workflows/workflowsUi";
import eventTemplatesWizardReducer from "@/slices/eventTemplates/eventTemplatesWizard";

import { documentsApi } from "./slices/documents/documentsApi";
import { documentTemplatesApi } from "./slices/documentTemplates/documentTemplatesApi";

import { notificationsApi } from "./slices/notifications/notificationsApi";

import { teamApi } from "./slices/team/teamApi";
import teamUi from "./slices/team/teamUi";

import { companyApi } from "./slices/company/companyApi";
import companyUi from "./slices/company/companyUi";

import { libraryApi } from "./slices/library/libraryApi";

import personalSettingsReducer from "./slices/settings/personalSettings";
import teamSettingsReducer from "./slices/settings/teamSettings";
import companySettingsReducer from "./slices/settings/companySettings";

import { signaturesApi } from "./slices/signatures/signaturesApi";

import authUiReducer from "./slices/auth/authUi";

import { mapsApi } from "@/slices/maps/mapsApi";
import { steps } from "framer-motion";

export const store = configureStore({
  reducer: {
    // deals
    [dealsApi.reducerPath]: dealsApi.reducer,
    dealsUi: dealsUiReducer,
    dealsWizard: dealsWizardReducer,

    // properties
    [propertiesApi.reducerPath]: propertiesApi.reducer,
    propertiesUi: propertiesUiReducer,
    propertiesWizard: propertiesWizardReducer,

    // persons
    [personsApi.reducerPath]: personsApi.reducer,
    personsUi: personsUiReducer,

    // events
    [eventsApi.reducerPath]: eventsApi.reducer,
    eventsUi: eventsUiReducer,

    // users
    [usersApi.reducerPath]: usersApi.reducer,
    [publicUsersApi.reducerPath]: publicUsersApi.reducer,

    // insights
    [insightsApi.reducerPath]: insightsApi.reducer,
    insightsUi: insightsUiReducer,

    // invites
    [invitesApi.reducerPath]: invitesApi.reducer,
    [publicInvitesApi.reducerPath]: publicInvitesApi.reducer,
    invitesUi: invitesUiReducer,

    // workflows
    [workflowTemplatesApi.reducerPath]: workflowTemplatesApi.reducer,
    [workflowsApi.reducerPath]: workflowsApi.reducer,
    [stepTemplatesApi.reducerPath]: stepTemplatesApi.reducer,
    [eventTemplatesApi.reducerPath]: eventTemplatesApi.reducer,
    [stepsApi.reducerPath]: stepsApi.reducer,
    workflowsUi: workflowsUI,
    eventTemplatesWizard: eventTemplatesWizardReducer,

    // onboarding
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    onboardingUi: onboardingUiReducer,

    // documents
    [documentsApi.reducerPath]: documentsApi.reducer,

    // document templates
    [documentTemplatesApi.reducerPath]: documentTemplatesApi.reducer,

    // notifications
    [notificationsApi.reducerPath]: notificationsApi.reducer,

    // team
    [teamApi.reducerPath]: teamApi.reducer,
    teamUi: teamUi,

    // company
    [companyApi.reducerPath]: companyApi.reducer,
    companyUi: companyUi,

    // library
    [libraryApi.reducerPath]: libraryApi.reducer,

    // settings
    personalSettings: personalSettingsReducer,
    teamSettings: teamSettingsReducer,
    companySettings: companySettingsReducer,

    // signatures
    [signaturesApi.reducerPath]: signaturesApi.reducer,

    // auth
    authUi: authUiReducer,

    // maps
    [mapsApi.reducerPath]: mapsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      dealsApi.middleware,
      propertiesApi.middleware,
      personsApi.middleware,
      eventsApi.middleware,
      usersApi.middleware,
      publicUsersApi.middleware,
      insightsApi.middleware,
      invitesApi.middleware,
      publicInvitesApi.middleware,
      onboardingApi.middleware,
      workflowTemplatesApi.middleware,
      workflowsApi.middleware,
      stepTemplatesApi.middleware,
      eventTemplatesApi.middleware,
      stepsApi.middleware,
      documentsApi.middleware,
      documentTemplatesApi.middleware,
      notificationsApi.middleware,
      teamApi.middleware,
      companyApi.middleware,
      libraryApi.middleware,
      mapsApi.middleware,
      signaturesApi.middleware
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
