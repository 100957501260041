import { Auth } from "@/hooks/auth";
import { Outlet } from "react-router-dom";
import { Flex } from "@/components/Flex";

export const Platform = () => {
  return (
    <Auth>
      <Flex direction="row">
        <div className="flex-1">
          <Outlet />
        </div>
      </Flex>
    </Auth>
  );
};
